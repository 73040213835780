<script>
/**
 * Profile component
 */
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
}
</script>
<template>
  <div class="card overflow-hidden">
    <div class="bg-soft-primary">
      <div class="row">
        <div class="col-6">
          <div class="text-primary p-3">
            <h5 class="text-primary">Witaj {{ data.firstName }}!</h5>
            <p>Tutaj znajdziesz wszystkie informacje o sobie.</p>
          </div>
        </div>
        <div class="col-6 align-self-end">
          <img src="@/assets/images/vector_volunteers0.png" alt class="img-fluid" />
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <div class="col-sm-7">
          <div class="avatar-xl profile-user-wid-xl mb-4">

             <img v-if="data.profileImageUrl !== ''"
              class="img-thumbnail rounded-circle"
              v-bind:src="data.profileImageUrl"
              alt="Header Avatar"
            />
            <img v-if="data.profileImageUrl === ''"
              class="img-thumbnail rounded-circle"
              src="@/assets/images/users/avatar-1.jpg"
              alt="Header Avatar"
            />

          </div>
          <h5 class="font-size-15 text-truncate">{{ data.firstName }} {{ data.lastName }}</h5>
          <p class="text-muted mb-0">{{ data.position }}</p>
        </div>

        <div class="col-sm-5">
          <div class="pt-4">
            <div class="row">
              <div class="col-12 mt-2">
                <h5 class="font-size-15"></h5>

              </div>
            </div>
            <div class="mt-2">

            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end card-body -->
  </div>
  <!-- end card -->
</template>
