<script>

    import {minLength, required, sameAs} from 'vuelidate/lib/validators'
    import Vue from "vue";

    import Repository from "../../app/repository/repository-factory";

    const UserAccountRepository = Repository.get("UserAccountRepository");

    export default {
        props: {
            accountUuid: {
                type: String,
                required: true
            },
        },
        data() {
            return {
                preloader: true,
                submitted: false,
                form: {
                    oldPassword: '',
                    newPassword1: '',
                    newPassword2: '',
                }
            }
        },
        validations: {
            form: {
                oldPassword: {required },
                newPassword1: {required, minLength: minLength(12) },
                newPassword2: {required, minLength: minLength(12), sameAsPassword1: sameAs('newPassword1') },
            }
        },
        methods: {
            changePassword() {
                this.formSubmit();

                if (this.$v.form.$error === true) {
                    return false;
                }

                UserAccountRepository.changePassword(this.form).then(() => {

                    Vue.swal({
                        icon: "success",
                        toast: false,
                        position: 'top',
                        title: "SUKCES! ",
                        text: 'Hasło zostało zmienione pomyślnie! Wyloguj się i zaloguj ponownie.',
                        showConfirmButton: false,
                        timer: 3500,
                        onClose: () => {
                            this.submitted = false;
                            this.form.oldPassword = '';
                            this.form.newPassword1 = '';
                            this.form.newPassword2 = '';
                        }
                    });
                }).catch(error => {
                    Vue.swal({
                        icon: "error",
                        position: 'top',
                        title: "Wystąpił błąd. Spróbuj jeszcze raz!",
                        text: error.response.data.message,
                        showConfirmButton: true
                    });
                })
            },
            formSubmit() {
                this.submitted = true;
                this.$v.form.$touch()
            },
        }
    };
</script>

<template>
    <div class="row">
        <div class="col-lg-12">
                        <form class="needs-validation" @submit.prevent="formSubmit">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <input
                                                id="oldPassword"
                                                v-model="form.oldPassword"
                                                type="password"
                                                class="form-control"
                                                placeholder="Stare hasło"
                                                :class="{ 'is-invalid': submitted && $v.form.oldPassword.$error }"
                                        />
                                        <div v-if="submitted && $v.form.oldPassword.$error" class="invalid-feedback">
                                            <span v-if="!$v.form.oldPassword.required">Ta wartość jest wymagana.<br></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <input
                                                id="newPassword1"
                                                v-model="form.newPassword1"
                                                type="password"
                                                class="form-control"
                                                placeholder="Nowe hasło*"
                                                :class="{ 'is-invalid': submitted && $v.form.newPassword1.$error }"
                                        />
                                        <div v-if="submitted && $v.form.newPassword1.$error"
                                             class="invalid-feedback">
                                            <span v-if="!$v.form.newPassword1.required">Ta wartość jest wymagana.<br></span>
                                            <span v-if="!$v.form.newPassword1.minLength">Wartość musi zawierać minimum 12 znaków.<br></span>
                                            <span v-if="!$v.form.newPassword2.sameAsPassword1">Hasła są różne.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <input
                                                id="newPassword2"
                                                v-model="form.newPassword2"
                                                type="password"
                                                class="form-control"
                                                placeholder="Nowe hasło - powtórz*"
                                                :class="{ 'is-invalid': submitted && $v.form.newPassword2.$error }"
                                        />
                                        <div v-if="submitted && $v.form.newPassword2.$error"
                                             class="invalid-feedback">
                                            <span v-if="!$v.form.newPassword2.required">Ta wartość jest wymagana.<br></span>
                                            <span v-if="!$v.form.newPassword2.minLength">Wartość musi zawierać minimum 12 znaków.<br></span>
                                            <span v-if="!$v.form.newPassword2.sameAsPassword1">Hasła są różne.</span>
                                        </div>
                                        <span class="mt-5 font-size-11">* Nowe hasło musi mieć długość minimum 12 znaków. <br>Najlepiej, jeżeli będzie składało się z małych i DUŻYCH liter oraz cyfr i znaków specjalnych. </span>
                                    </div>
                                </div>
                            </div>

                            <button class="btn btn-danger" v-on:click="changePassword" type="submit">Zmień hasło</button>
                        </form>
            <!-- end card -->
        </div>
        <!-- end col -->
    </div>
</template>
