<script>
import {mapState} from "vuex";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Profile from "@/components/account/account-profile";
import ChangePassword from "@/components/account/account-change-password";
import Preloader from "@/components/widgets/preloader";

import Repository from "../../../app/repository/repository-factory";

const UserAccountRepository = Repository.get("UserAccountRepository");

    /**
     * Contacts-grid component
     */
    export default {
        components: {Layout, PageHeader, Profile, ChangePassword, Preloader},
        data() {
            return {
                preloader: true,
                userData: {},
                title: "Profil opiekuna",
                items: [
                    {
                        text: "Profil opiekuna",
                        active: true
                    }
                ]
            };
        },
        computed: {
            ...mapState({
                authState: state => state.auth
            }),
        },
        created() {
            this.getAll();
        },
        methods: {
            getAll() {
                UserAccountRepository.getMyAccount().then((response) => {
                    this.userData = response.data;
                }).catch(error => {
                    console.log(error);
                }).finally(() => this.preloader = false)
            }
        }
    };
</script>

<template>
    <Layout>
        <preloader v-if="preloader"></preloader>

        <PageHeader :title="title" :items="items"/>

        <div class="row">
            <div class="col-md-6 col-lg-6 col-xl-6">
                <Profile style="min-height: 315px;" :data="userData"/>
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3">
                <template v-if="userData.uuid === authState.user.uuid">
                    <div class="card" style="min-height: 310px;">
                        <div class="card-body">
                            <h4 class="card-title">Zmiana hasła</h4>
                            <ChangePassword account-uuid="userData.uuid"></ChangePassword>
                        </div>
                    </div>
                </template>
            </div>

            <div class="col-md-6 col-lg-auto col-xl-3">
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 col-lg-6 col-xl-6">
                <div class="card" style="min-height: 280px;">
                    <div class="card-body">
                        <h4 class="card-title">Dane podstawowe</h4>
                        <div class="table-responsive mb-0">
                            <table class="table">
                                <tbody>
                                <tr>
                                    <th scope="row">Imię:</th>
                                    <td>{{ userData.firstName }}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Nazwisko:</th>
                                    <td>{{ userData.lastName }}</td>
                                </tr>
                                <tr>
                                    <th scope="row">E-mail:</th>
                                    <td><a :href="'mailto:' + userData.email">{{ userData.email }}</a></td>
                                </tr>
                                <tr>
                                    <th scope="row">Stanowisko:</th>
                                    <td>{{ userData.position }}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Utworzenie konta:</th>
                                    <td>{{ userData.createdAt }}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Ostatnia aktualizacja:</th>
                                    <td>{{ userData.updatedAt }}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Zgoda RODO:</th>
                                  <td v-if="userData.rodoAccepted">Tak</td>
                                  <td v-if="!userData.rodoAccepted" style="color: #9e0000">Nie</td>
                                </tr>
                                <tr>
                                    <th scope="row">Konto aktywne?</th>
                                    <td v-if="userData.isActive">Tak</td>
                                    <td v-if="!userData.isActive" style="color: #9e0000">Nie</td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-auto col-xl-3">

            </div>
            <div class="col-md-6 col-lg-auto col-xl-3">

            </div>
        </div>
            <!-- end row -->
    </Layout>
</template>
